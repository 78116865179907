import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';
import { stringFormat } from '@/utils/index.js';
import { Form, FormStatus } from '@/views/forms/types';
import { userFormsUrl, formsUrl, formsApiEndpoint } from '@/utils/url-manager.js';

interface ISubmissionsCount {
  id: string;
  totalCount: number;
  unreadCount: number;
}

interface IForm extends Form {
  archived: boolean;
}

export default defineStore('formsStore', () => {
  const forms = ref<Form[]>([]);

  const changeFormStatus = ({ id, status }: { id: string; status: FormStatus }) => {
    forms.value = forms.value.map((item) => {
      if (item.id === id) {
        item.status = status;
      }
      return item;
    });
  };

  async function getFormWidget(formId: string) {
    const id = Date.now().toString();

    return `<script type="text/javascript" src="${formsApiEndpoint}widget/${formId}"></script>
<div id="plumsail-form-${id}"><div class="fd-form-loading"></div></div>
<script type="text/javascript">
  var fd = new Plumsail.Form("#plumsail-form-${id}", "${formId}")
</script>`;
  }

  async function deleteForm(form: Form) {
    await axios.delete(`${formsApiEndpoint}v2/designer/forms/${form.id}`);

    const deletedIndex = forms.value.indexOf(form);

    if (deletedIndex !== -1) {
      forms.value = forms.value.filter((_, index) => index !== deletedIndex);
    }
  }

  async function activateForm(form: Form) {
    await axios.post(`${formsApiEndpoint}v2/designer/forms/${form.id}/activate`);
    changeFormStatus({ id: form.id, status: form.approval });
  }

  async function archiveForm(form: Form) {
    await axios.post(`${formsApiEndpoint}v2/designer/forms/${form.id}/archive`);
    changeFormStatus({ id: form.id, status: FormStatus.Archived });
  }

  async function loadForms() {
    const getFormBaseUrl = async () => {
      if (userFormsUrl === formsUrl) {
        return formsUrl;
      }

      const domain = (await axios.get<string>(`${formsApiEndpoint}v2/user/domain`)).data;
      return domain ? stringFormat(userFormsUrl, domain) : formsUrl;
    };

    const getForms = async () => (await axios.get<IForm[]>(`${formsApiEndpoint}v2/designer/forms`)).data;
    const getSubmissionsCount = async () => (await axios.get<ISubmissionsCount[]>(`${formsApiEndpoint}forms/submissions/count`)).data;

    const [currentForms, formBaseUrl, submissionsCount] = await Promise.all([
      getForms(),
      getFormBaseUrl(),
      getSubmissionsCount(),
    ]);

    currentForms.forEach((form) => {
      form.url = `${formBaseUrl}${form.id}`;
      form.status = form.archived ? FormStatus.Archived : form.approval;

      const totals = submissionsCount.find((total) => total.id === form.id) || {
        totalCount: 0,
        unreadCount: 0,
      };

      form.totalSubmissionsCount = totals.totalCount;
      form.unreadSubmissionsCount = totals.unreadCount;
    });

    forms.value = currentForms as Form[];
  }

  async function loadForm({ formId }: { formId: string }) {
    try {
      return axios.get<IForm>(`${formsApiEndpoint}v2/designer/forms/${formId}`);
    } catch (error) {
      throw new Error('Failed to load current form');
    }
  }

  return {
    forms,

    getFormWidget,
    deleteForm,
    activateForm,
    archiveForm,
    loadForms,
    loadForm,
  };
});
