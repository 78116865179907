
import { Component } from 'vue-property-decorator';
import BooleanField from './boolean-field.vue';
import NumberField from './number-field.vue';
import AttachmentsField from './attachments-field.vue';
import DateField from './date-field.vue';
import DateTimeField from './date-time-field.vue';
import SystemDateTimeField from './system-date-time-field.vue';
import ChoicesField from './choices-field.vue';
import MultilineTextField from './multiline-text-field.vue';
import SignatureField from './signature-field.vue';
import LikertField from './likert-field.vue';
import DataTableField from './data-table-field.vue';
import TimeField from './time-field.vue';
import AccountField from './account-field.vue';
import Base from './base.vue';
import { FieldTypes } from '../types';
import AirtableAttachmentField from './airtable/airtable-attachment-field.vue';
import AirtableBarcodeField from './airtable/airtable-barcode-field.vue';

@Component({
  components: {
    BooleanField,
    NumberField,
    AttachmentsField,
    DateField,
    DateTimeField,
    SystemDateTimeField,
    ChoicesField,
    MultilineTextField,
    SignatureField,
    LikertField,
    DataTableField,
    TimeField,
    AccountField,
    AirtableAttachmentField,
    AirtableBarcodeField,
  },
})
export default class BaseField extends Base {
  get fieldComponent() {
    if (!this.schema) {
      return null;
    }
    switch (this.schema.fieldType) {
      case FieldTypes.Toggle:
      case FieldTypes.AirtableCheckbox:
        return 'boolean-field';
      case FieldTypes.Attachments:
        return 'attachments-field';
      case FieldTypes.Date:
      case FieldTypes.AirtableDate:
        return 'date-field';
      case FieldTypes.DateTime:
      case FieldTypes.AirtableDateTime:
        return 'date-time-field';
      case FieldTypes.SystemDateTime:
        return 'system-date-time-field';
      case FieldTypes.MultipleChoice:
      case FieldTypes.Checkboxes:
      case FieldTypes.DropDown:
      case FieldTypes.AirtableMultipleSelect:
      case FieldTypes.AirtableLinkedRecord:
        return 'choices-field';
      case FieldTypes.MultiLineText:
      case FieldTypes.MultilineTextBox:
      case FieldTypes.AirtableLongText:
        return 'multiline-text-field';
      case FieldTypes.Signature:
        return 'signature-field';
      case FieldTypes.LikertScale:
        return 'likert-field';
      case FieldTypes.DataTable:
        return 'data-table-field';
      case FieldTypes.Number:
      case FieldTypes.Numeric:
      case FieldTypes.AirtableNumber:
      case FieldTypes.AirtableCurrency:
        return 'number-field';
      case FieldTypes.Modified:
      case FieldTypes.Created:
        return 'time-field';
      case FieldTypes.Account:
        return 'account-field';
      case FieldTypes.AirtableAttachment:
        return 'airtable-attachment-field';
      case FieldTypes.AirtableBarcode:
        return 'airtable-barcode-field';
      default:
        return null;
    }
  }
}
