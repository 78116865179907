import {
  Column, Row, RowAction, Action,
} from './components/table/types';

export enum FormStatus {
  Review = -2,
  Disabled = -1,
  Regular = 0,
  Approved = 1,
  Archived = 2
}
export interface Form {
  id: string;
  name: string;
  totalSubmissionsCount: number;
  unreadSubmissionsCount: number;
  modified: string;
  status: FormStatus;
  type: number;
  url: string;
  approval: FormStatus;
}

export interface FormRow extends Row {
  id: string;
  name: {
    title: string;
    link: {
      name: string, params: { formId: string }
    }
  };
  submissions: {
    total: number;
    unread: number;
  };
  modified: {
    textDate: string,
    date: Date
  };
  status: string;
  type: number;
  open: () => void;
  edit: () => void;
  archive: () => Promise<void>;
  delete: (confirmationDisabled?: boolean) => Promise<void>;
  copy: () => void;
  activate: () => Promise<void>;
}

export interface FormsRowAction extends RowAction {
  handler: (row: FormRow) => void;
  visible: (row: FormRow) => boolean;
}

export interface FormColumn extends Column {
  actions?: FormsRowAction[];
  contextMenuActions?: FormsRowAction[];
  customSort?: (a: FormRow, b: FormRow, isAsc: boolean) => number;
}

export type FormAction = Action;
