export interface Submission {
  __id: string;
  formId: string;
  lastModified: string;
  isRead: boolean;
  __created: string;
  __modified: string;
  created: string;
  dataJson: string;
  [key: string]: any;
}

export interface SubmissionItem {
  created: string;
  dataJson: string;
  id: string;
  isRead: boolean;
  lastModified?: string;
}

export interface SubmissionsResponse {
  items: SubmissionItem[];
  offset: number;
  total: number;
}

export interface SubmissionsContent {
  items: Submission[];
  offset: number;
  total: number;
  lastChunk: Submission[];
}

export type SubmissionsParser = (
  oldSubmissions: Submission[],
  newSubmissions: SubmissionsResponse,
  formId: string
) => SubmissionsContent;

export enum FieldTypes {
  Toggle = 'Toggle',
  Attachments = 'Attachments',
  Date = 'Date',
  DateTime = 'DateTime',
  SystemDateTime = 'SystemDateTime',
  MultipleChoice = 'MultipleChoice',
  Checkboxes = 'Checkboxes',
  DropDown = 'DropDown',
  SingleLineText = 'SingleLineText',
  MultiLineText = 'MultiLineText',
  TextBox = 'TextBox',
  MultilineTextBox = 'MultilineTextBox',
  Signature = 'Signature',
  LikertScale = 'LikertScale',
  DataTable = 'DataTable',
  Number = 'Number',
  Numeric = 'Numeric',
  Boolean = 'Boolean',
  String = 'String',
  Modified = '__modified',
  Created = '__created',
  Account = 'Account',
  Id = '__id',

  AirtableAttachment = '5001',
  AirtableBarcode = '5003',
  AirtableCheckbox = '5005',
  AirtableCurrency = '5010',
  AirtableDate = '5011',
  AirtableDateTime = '5012',
  AirtableLinkedRecord = '5018',
  AirtableLongText = '5019',
  AirtableMultipleSelect = '5022',
  AirtableNumber = '5023',
}

export enum SubmissionViewType {
  Modal = 'Modal',
  Page = 'Page',
}

export enum SubmissionFieldType {
  Table = 'Table',
  Modal = 'Modal',
}

export interface FieldSchema {
  fieldType: FieldTypes;
  internalName: string;
  title: string;
  format?: string;
  mode?: string;
  culture?: string;
  isMultiple?: boolean;
  type?: string;
  height?: string;
  width?: string;
  answers?: string[];
  questions?: string[];
  columns?: FieldSchema[];
}

export interface SettingsSchema {
  columns: string[];
  showTitles: boolean;
}

export interface ViewSettingsSchema {
  internalName: string;
  alias: string;
  description: string | null;
  selected: boolean;
}

export interface ModalSettings {
  component: any;
  props: {
    submission: Partial<Submission>;
  };
  modalProps?: object;
}

export interface SaveSettings {
  selectedFields: string[];
  showTitles: SettingsSchema['showTitles'];
}

export enum SubmissionType {
  Submission = 'Submission',
  Draft = 'Draft',
}
