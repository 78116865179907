
import { Component } from 'vue-property-decorator';
import Base from './base.vue';
import VisualWrapper from './visual-wrapper.vue';

@Component({
  components: {
    VisualWrapper,
  },
})
export default class MultilineTextField extends Base {}
