
import { Component } from 'vue-property-decorator';
import Base from './base.vue';
import VisualWrapper from './visual-wrapper.vue';

@Component({
  components: {
    VisualWrapper,
  },
})
export default class LikertField extends Base {
  private getAnswerString(questionIndex: number) {
    if (!this.value || !this.value[questionIndex]) return '';

    if (Array.isArray(this.value[questionIndex])) {
      if (this.schema.type === 'Checkbox') return this.value[questionIndex].join(', ');

      if (this.schema.answers) {
        return this.schema.answers
          .map((a, i) => `${a} - ${this.value[questionIndex][i]}`)
          .join(', ');
      }

      return '';
    }

    return String(this.value[questionIndex]);
  }

  get questions() {
    if (Array.isArray(this.schema.questions)) {
      return this.schema.questions;
    }
    return [];
  }

  get answers() {
    return this.questions.map((_, i) => this.getAnswerString(i));
  }

  get isAnswered() {
    return this.answers.some((a) => a);
  }
}
