
import { Component } from 'vue-property-decorator';
import Base from './base.vue';
import VisualWrapper from './visual-wrapper.vue';

@Component({
  components: {
    VisualWrapper,
  },
})
export default class SignatureField extends Base {
  get imgStyle() {
    const { width, height } = this.schema;

    const areDimensionsDefined = width && height;

    const imgStyle = {
      width: areDimensionsDefined && width >= height ? `${width}px` : 'auto',
      height: areDimensionsDefined && width < height ? `${height}px` : 'auto',
    };

    return imgStyle;
  }
}
