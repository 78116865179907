
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FieldSchema, SubmissionFieldType } from '../types';

@Component
export default class Base extends Vue {
  @Prop({ required: true })
  public readonly value: any;

  @Prop({ required: true })
  public readonly schema: FieldSchema;

  @Prop({ required: false, default: SubmissionFieldType.Table })
  public readonly type: SubmissionFieldType;

  get isModal() {
    return this.type === SubmissionFieldType.Modal;
  }
}
