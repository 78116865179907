/* eslint-disable no-underscore-dangle */
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import axios from 'axios';
import { Route } from 'vue-router';
import { useTeamsStore, useAppStore } from '../index';
import {
  FieldTypes,
  FieldSchema,
  SaveSettings,
  SettingsSchema,
  Submission,
  SubmissionsResponse,
  SubmissionsParser,
  SubmissionsContent,
  SubmissionType,
  SubmissionItem,
} from '@/views/forms/submissions/types';
import { formsApiEndpoint } from '@/utils/url-manager.js';

const formsEndpoint: string = formsApiEndpoint;

function parseSubmission(submission: SubmissionItem, formId: string): Submission {
  return {
    __id: submission.id,
    formId,
    lastModified: submission.lastModified,
    isRead: submission.isRead,
    __created: submission.created,
    __modified: submission.lastModified,
    created: submission.created,
    dataJson: submission.dataJson,
    ...JSON.parse(submission.dataJson),
  };
}

const parseSubmissions: SubmissionsParser = (oldSubmissions, newSubmissions, formId) => {
  const lastChunk: Submission[] = newSubmissions.items.map((s) => parseSubmission(s, formId));

  return {
    items: [...oldSubmissions, ...lastChunk],
    lastChunk,
    total: newSubmissions.total,
    offset: newSubmissions.offset,
  };
};

export default defineStore('formsSubmissions', () => {
  const teamStore = useTeamsStore();
  const appStore = useAppStore();

  const type = ref<SubmissionType>(SubmissionType.Submission);
  const submissions = ref<SubmissionsContent | null>(null);
  const fields = ref<FieldSchema[] | null>(null);
  const settings = ref<SettingsSchema | null>(null);
  const abortController = ref<AbortController>(new AbortController());
  const selectedSubmissions = ref<string[]>([]);
  const getFormId = computed(() => {
    const route = appStore.$state.route as unknown as Route;
    return route.params.formId;
  });
  const getSubmissions = computed(() => submissions.value?.items || []);
  const getOffset = computed(() => submissions.value?.items?.length || 0);
  const getPageSize = computed(() => 50);
  const getTotalSubmissions = computed(() => submissions.value?.total || 0);
  const getFields = computed(() => fields.value || []);

  const sumbissionsEndpoint = computed(() => (type.value === SubmissionType.Submission
    ? `${formsEndpoint}forms/${getFormId.value}/submissions`
    : `${formsEndpoint}forms/${getFormId.value}/drafts`));

  const getPage = computed(() => {
    if (submissions.value) {
      return Math.ceil(submissions.value.items.length / getPageSize.value);
    }
    return 0;
  });

  const getSettings = computed<SettingsSchema['columns']>(() => (settings.value ? settings.value.columns : getFields.value.map((field) => field.internalName)));

  const getShowTitles = computed<SettingsSchema['showTitles']>(
    () => settings.value?.showTitles ?? false,
  );

  const getSortedFields = computed<FieldSchema[]>(() => {
    const systemFields = [
      {
        fieldType: FieldTypes.Id,
        internalName: FieldTypes.Id,
        title: 'Submission ID',
      },
      {
        fieldType: FieldTypes.Created,
        internalName: FieldTypes.Created,
        title: 'Created',
      },
      {
        fieldType: FieldTypes.Modified,
        internalName: FieldTypes.Modified,
        title: 'Modified',
      },
    ];

    return [...getFields.value, ...systemFields].sort((a, b) => {
      const aIndex = getSettings.value.findIndex((fieldName) => fieldName === a.internalName);
      const bIndex = getSettings.value.findIndex((fieldName) => fieldName === b.internalName);

      if (bIndex !== -1 && aIndex !== -1) {
        return aIndex - bIndex;
      }

      if (aIndex !== -1) return -1;
      if (bIndex !== -1) return 1;

      return 0;
    });
  });

  const getSubmissionsLastChunk = computed(() => submissions.value?.lastChunk ?? []);

  function cancelPendingRequests() {
    abortController.value.abort();
    abortController.value = new AbortController();
  }

  async function loadFields() {
    try {
      const newFields = (
        await axios.get<FieldSchema[]>(`${formsEndpoint}account/schema/${getFormId.value}`, {
          headers: { Pragma: 'no-cache' },
        })
      ).data;

      fields.value = newFields;
    } catch (error) {
      throw new Error('Failed to load fields of submissions');
    }
  }

  async function loadSettings() {
    try {
      const newSettings = (
        await axios.get<SettingsSchema>(
          `${formsEndpoint}v2/designer/forms/${getFormId.value}/posts-view-settings`,
          {
            headers: { Pragma: 'no-cache' },
          },
        )
      ).data;

      if (typeof newSettings === 'string') {
        settings.value = null;
        return;
      }

      settings.value = newSettings;
    } catch (error) {
      throw new Error('Failed to load settings of submissions');
    }
  }

  async function loadSubmissionsPage() {
    const { signal } = abortController.value;

    try {
      const submissionsData = (
        await axios.get<SubmissionsResponse>(
          `${sumbissionsEndpoint.value}?&limit=${getPageSize.value}&offset=${getOffset.value}`,
          {
            signal,
            headers: { Pragma: 'no-cache' },
          },
        )
      ).data;

      const newSubmissions = parseSubmissions(
        getSubmissions.value,
        submissionsData,
        getFormId.value,
      );

      submissions.value = newSubmissions;
    } catch (error) {
      if (!signal.aborted) {
        throw new Error('Failed to load new submissions');
      }
    }
  }

  async function getSubmissionById({ id }: { id: string }) {
    try {
      const { data } = await axios.get<Submission>(`${sumbissionsEndpoint.value}/${id}`);

      return data;
    } catch (error) {
      throw new Error('Failed to load submission by ID');
    }
  }

  async function readSubmissions({ ids }: { ids: string[] }) {
    try {
      await axios.put(`${sumbissionsEndpoint.value}/read`, {
        ids,
      });

      const newSubmissions: Submission[] = getSubmissions.value.map((submission) => {
        if (ids.includes(submission.__id)) {
          return { ...submission, isRead: true };
        }
        return submission;
      });

      if (submissions.value) {
        submissions.value = { ...submissions.value, items: newSubmissions };
      }
    } catch (error) {
      throw new Error('Failed to read submissions');
    }
  }

  async function unreadSubmissions({ ids }: { ids: string[] }) {
    try {
      await axios.put(`${sumbissionsEndpoint.value}/unread`, {
        ids,
      });

      const newSubmissions: Submission[] = getSubmissions.value.map((submission) => {
        if (ids.includes(submission.__id)) {
          return { ...submission, isRead: false };
        }
        return submission;
      });

      if (submissions.value) {
        submissions.value = { ...submissions.value, items: newSubmissions };
      }
    } catch (error) {
      throw new Error('Failed to unread submissions');
    }
  }

  async function deleteSubmissions({ ids }: { ids: string[] }) {
    try {
      await axios.delete(`${sumbissionsEndpoint.value}`, {
        data: {
          ids,
        },
      });

      const newSubmissions = getSubmissions.value.filter(
        (submission) => !ids.includes(submission.__id),
      );

      if (submissions.value) {
        submissions.value = { ...submissions.value, items: newSubmissions };
      }
    } catch (error) {
      throw new Error('Failed to delete submissions');
    }
  }

  async function removeSubmissions({ ids }: { ids: string[] }) {
    try {
      const newSubmissions = getSubmissions.value.filter(
        (submission) => !ids.includes(submission.__id),
      );

      if (submissions.value) {
        submissions.value = { ...submissions.value, items: newSubmissions };
      }
    } catch (error) {
      throw new Error('Failed to remove submissions');
    }
  }

  async function refreshSubmission({ id }: { id: string }) {
    try {
      await axios.get<Submission>(`${sumbissionsEndpoint.value}/${id}`);

      if (submissions.value) {
        submissions.value = { ...submissions.value, items: getSubmissions.value };
      }
    } catch (error) {
      throw new Error('Failed to replace submissions');
    }
  }

  async function exportAll() {
    try {
      const report = (
        await axios.post<{ ids: null }, { data: string }>(`${sumbissionsEndpoint.value}/export`, {
          ids: null,
        })
      ).data;

      const downloadUrl = `${sumbissionsEndpoint.value}/export?userId=${teamStore.getSelectedTeam.ownerId}&report=${report}`;
      window.location = downloadUrl as unknown as Location;
    } catch (error) {
      throw new Error('Failed to export all submissions');
    }
  }

  async function exportSelected({ ids }: { ids: string[] }) {
    try {
      const report = (
        await axios.post<{ ids: string[] }, { data: string }>(
          `${sumbissionsEndpoint.value}/export`,
          {
            ids,
          },
        )
      ).data;

      const downloadUrl = `${sumbissionsEndpoint.value}/export?userId=${teamStore.getSelectedTeam.ownerId}&report=${report}`;
      window.location = downloadUrl as unknown as Location;
    } catch (error) {
      throw new Error('Failed to export selected submissions');
    }
  }

  function saveSettings({ selectedFields, showTitles }: SaveSettings) {
    try {
      return axios.put(`${formsEndpoint}v2/designer/forms/${getFormId.value}`, {
        postsViewSettings: JSON.stringify({ columns: selectedFields, showTitles }),
      });
    } catch (error) {
      throw new Error('Failed to export selected submissions');
    }
  }

  function loadData() {
    return Promise.all([loadFields(), loadSettings()]);
  }

  function selectSubmission(value?: string) {
    if (!value) {
      const currentSubmissions = submissions.value?.items || [];
      selectedSubmissions.value = currentSubmissions.map((submission) => submission.__id);
    } else selectedSubmissions.value.push(value);
  }

  function deselectSubmission(value?: string) {
    if (!value) selectedSubmissions.value = [];
    else selectedSubmissions.value.splice(selectedSubmissions.value.indexOf(value), 1);
  }

  function clearSubmissions() {
    submissions.value = null;
  }

  function clear() {
    type.value = SubmissionType.Submission;
    submissions.value = null;
    fields.value = null;
    settings.value = null;
    selectedSubmissions.value = [];
  }

  function setType(newType: SubmissionType) {
    type.value = newType;
  }

  return {
    type,
    submissions,
    fields,
    settings,
    abortController,
    selectedSubmissions,
    getFormId,
    sumbissionsEndpoint,
    getSubmissions,
    getPage,
    getPageSize,
    getOffset,
    getTotalSubmissions,
    getFields,
    getSettings,
    getShowTitles,
    getSortedFields,
    getSubmissionsLastChunk,

    cancelPendingRequests,
    loadFields,
    loadSettings,
    loadSubmissionsPage,
    getSubmissionById,
    readSubmissions,
    unreadSubmissions,
    deleteSubmissions,
    removeSubmissions,
    refreshSubmission,
    exportAll,
    exportSelected,
    saveSettings,
    loadData,
    selectSubmission,
    deselectSubmission,
    clearSubmissions,
    clear,
    setType,
  };
});
