
import { Component } from 'vue-property-decorator';
import { FieldTypes, FieldSchema } from '../types';
import Base from './base.vue';
import VisualWrapper from './visual-wrapper.vue';

@Component({
  components: {
    VisualWrapper,
  },
})
export default class DataTableField extends Base {
  private getCellStringValue(column: FieldSchema, value: string[] | string) {
    if (!value && column.fieldType !== FieldTypes.Number) {
      return '';
    }
    switch (column.fieldType) {
      case FieldTypes.Date:
        if (typeof value === 'string') {
          return new Date(value).toLocaleDateString();
        }
        return '';
      case FieldTypes.DateTime:
        if (typeof value === 'string') {
          return new Date(value).toLocaleString();
        }
        return '';
      case FieldTypes.Boolean:
        return '&#10004;';
      default:
        return Array.isArray(value) ? value.join(', ') : value;
    }
  }

  private columnStyle(column: FieldSchema) {
    switch (column.fieldType) {
      case FieldTypes.Number:
        return {
          'text-align': 'right',
        };
      case FieldTypes.Boolean:
        return {
          'text-align': 'center',
        };
      case FieldTypes.Date:
      case FieldTypes.DateTime:
        return {
          'text-align': 'left',
          'word-break': 'initial',
        };
      default:
        return {
          'text-align': 'left',
        };
    }
  }

  get viewRows() {
    if (this.isModal) return this.value;
    return this.value.slice(0, 2);
  }
}
