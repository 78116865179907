
import { formatFullRelativeDate } from '@/utils';
import {
  Component, Vue, Ref, Prop,
} from 'vue-property-decorator';
import { mapState } from 'pinia';
import useFormsSubmissions from '@/stores/forms/submissions';
import BaseField from '@/views/forms/submissions/fields/base-field.vue';
import {
  FieldSchema,
  Submission,
  SubmissionViewType,
  SubmissionFieldType,
} from '@/views/forms/submissions/types';

@Component({
  components: {
    BaseField,
  },
  computed: {
    ...mapState(useFormsSubmissions, {
      fields: 'getSortedFields',
      showTitles: 'getShowTitles',
    }),
  },
})
export default class SubmissionView extends Vue {
  private fields: FieldSchema[];

  private showTitles: boolean;

  @Prop({ required: true })
  private readonly submission: Submission;

  @Prop({ required: false, default: SubmissionViewType.Modal })
  private readonly type: SubmissionViewType;

  @Ref()
  private readonly submissionGridRef: HTMLDivElement;

  private close() {
    this.$modal.hide('SubmissionView');
  }

  print() {
    const tab = window.open('');

    if (tab) {
      tab.document.write(
        `<title>${this.date}</title>`,
        document.head.innerHTML,
        this.submissionGridRef.innerHTML,
      );

      const interval = tab.setInterval(() => {
        if (tab.document.body.innerHTML) {
          tab.print();
          tab.clearInterval(interval);
          tab.close();
        }
      }, 1000);
    }
  }

  private isPrivateField(field: FieldSchema) {
    return field.internalName.startsWith('__');
  }

  private getTitle(field: FieldSchema) {
    if (this.isPrivateField(field)) {
      return field.title;
    }
    return field.internalName;
  }

  private showDescription(field: FieldSchema) {
    return this.showTitles && !this.isPrivateField(field);
  }

  private getValue(field: FieldSchema) {
    return this.submission[field.internalName];
  }

  get isModal() {
    return this.type === SubmissionViewType.Modal;
  }

  get date() {
    return formatFullRelativeDate(this.submission.created);
  }

  get SubmissionFieldType() {
    return SubmissionFieldType;
  }
}
