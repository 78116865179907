
import { Component } from 'vue-property-decorator';
import Base from './base.vue';

@Component
export default class TimeField extends Base {
  get time() {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    return new Intl.DateTimeFormat(undefined, options).format(new Date(this.value));
  }
}
